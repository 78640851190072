import { produce } from 'immer'
import type { StateCreator } from 'zustand'

import {
  ADDRESS_DEFAULTS,
  ENTERPRISE_CONTACT_INFORMATION_DEFAULTS,
  ENTERPRISE_DEFAULTS,
} from '../defaults'
import { middleware } from '../middleware'
import type { Address, Enterprise, EnterpriseContactInformation, Store } from '../types'

export type EnterpriseCustomerState = Enterprise & {
  _contactInformation: EnterpriseContactInformation
  _address: Address
}

export type EnterpriseCustomerActions = {
  setCustomer: (enterprise: Enterprise, address: Address) => void
  setContactInformation: (contact: EnterpriseContactInformation) => void
  setEnterprise: (enterprise: Enterprise) => void
}

export type EnterpriseCustomerStore = {
  enterpriseCustomer: EnterpriseCustomerState & EnterpriseCustomerActions
}

export const initialState: EnterpriseCustomerState = {
  _address: ADDRESS_DEFAULTS,
  _contactInformation: ENTERPRISE_CONTACT_INFORMATION_DEFAULTS,
  ...ENTERPRISE_DEFAULTS,
}

export const creator: StateCreator<
  Store,
  [['zustand/persist', unknown]],
  [],
  EnterpriseCustomerStore
> = (set, get) => ({
  enterpriseCustomer: {
    ...initialState,
    setCustomer: (enterprise, address) =>
      set(
        produce(get(), (state: Store) => {
          state.enterpriseCustomer = { ...state.enterpriseCustomer, ...enterprise }
          state.enterpriseCustomer._address = address
        }),
      ),
    setContactInformation: (contact) =>
      set(
        produce(get(), (state: Store) => {
          state.enterpriseCustomer._contactInformation = contact
        }),
      ),
    setEnterprise: (enterprise) =>
      set(
        produce(get(), (state: Store) => {
          state.enterpriseCustomer = { ...state.enterpriseCustomer, ...enterprise }
        }),
      ),
  },
})

export const createEnterpriseCustomerSlice = middleware(
  creator,
  (_prevState, _set, _get, _store) => {},
)
